import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section',
  template: `
    <p>
      section works!
    </p>
  `,
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  @Input() sectionTitle: string;

  constructor() { }

  ngOnInit() {
  }

}
