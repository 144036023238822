import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
<div #nav fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" [class.sticky]="fixedNav"
  class="mat-elevation-z4" style="color: #212121; padding: 12px 24px; background-color: #ffffff; z-index: 1000;">
  <img style="margin: 8px; width: 240px;" src="assets/logo/400dpiLogoCropped.png" alt="Mills Consulting"/>
  <div>
  <span class="nav-button" (click)="scrollTo(welcome)">Welcome!</span>
  <span class="nav-button" (click)="scrollTo(what)">What</span>
  <span class="nav-button" (click)="scrollTo(about)">About</span>
  <span class="nav-button" (click)="scrollTo(movingForward)">Moving Forward Together</span>
  <span class="nav-button" (click)="scrollTo(now)">Now</span>
  <a mat-icon-button color="primary" href="mailto:sheryl@millsconsult.com"><mat-icon>mail</mat-icon></a>
  </div>
</div>
<div class="content" [style.padding-top.px]="contentPadding">

<div #welcome class="section" fxLayout="column" fxLayoutAlign="center center">
  <h1>Welcome!</h1>
  <div class="section-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" class="mat-elevation-z2 info-card">
  <img fxFlex="25" style="width: 300px;" src="../assets/Sheryl.JPG"/>
  <div fxFlex="75">
  I am Sheryl Mills and I am “Mills Consulting”. Most likely you have been referred to me by a colleague or friend. They have referred you to me because they found the work we did together productive, value-added, and successful. This site is a quick, straightforward way to let you know how I can contribute to your projects and how we can move forward together.
  </div>
  </div>
  </div>
</div>

<div #what class="section" fxLayout="column" fxLayoutAlign="center center">
  <h1>what</h1>
  <h3>It’s all about change and learning and here’s what I bring to the table</h3>
  <div class="section-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
  <div fxFlex="33" class="mat-elevation-z2 info-card"><h3>Co-DESIGN</h3> We work together to design the pathway from your NOW to your desired FUTURE. Successful change needs a successful plan. I work with my clients to develop the plan that works best for their organization and their goals. This can include change management strategies, culture change initiatives, microlearning campaigns, curriculum and program development and renewal, and in-house training. Involved in this is a comprehensive analysis and set of recommendations.</div>
  <div fxFlex="33" class="mat-elevation-z2 info-card"><h3>Co-FACILITATE</h3>I am a professional facilitator and skilled educator. I use the latest brain research and active learning principles to create sessions that are engaging, effective, enjoyable, and productive. Some of the formats that are especially effective are design labs, open space, and change academies. In addition to facilitation, I also offer workshops tailored to your organization. Topic areas include moving beyond drama, effective communication, team building, and leadership development.</div>
  <div fxFlex="33" class="mat-elevation-z2 info-card"><h3>COACH-in-Context</h3> I work with you behind the scenes to prepare to lead your own planning sessions, meetings, or events and through this we work on the skills and competencies you are most interested in developing. Coaching is done IN CONTEXT and is specifically targeted to your learning goals.</div>
  </div>
  <div>
  I work with you to achieve your professional, personal, and organizational goals.

  Together you and I come up with the best plan for you. For references or to schedule an initial chat/consultation to determine fit, you can reach me at <a href="mailto:sheryl@millsconsult.com">sheryl@millsconsult.com</a>
  </div>
</div>

<div #about class="light section" fxLayout="column" fxLayoutAlign="center center">
  <h1>about</h1>
  <h3>Appreciating learning, leadership, teams, and effective communication</h3>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
  <div fxFlex="50"class="mat-elevation-z2 info-card">
  <h3>Experienced</h3>
  <p>Facilitating, faculty and staff development planning, curriculum and program revision, renewal, and creation of new programs, teaching university courses… I am at the point in my career where these experiences come together in uniquely effective ways.  I’ve worked at the University of Saskatchewan in all colleges and schools through my work with the Gwenna Moss Centre for Teaching and Learning, and the Saskatoon Board of Education as a teacher, building administrator, and school effectiveness consultant plus 25 years of contract work!</p>
  </div>
  <div fxFlex="50"class="mat-elevation-z2 info-card">
  <h3>Eclectic</h3>
  <p>I’ve written—and co-authored—many papers, articles, books, and book chapters (along with a thesis and a dissertation) on topics ranging from creativity to successful teams.</p>
  <p>For six years I had a painting studio. Paintings from my studio have made their way to Paris, Vancouver, Montreal, and Beijing as well as many homes in Saskatchewan.  I’ve designed homes and renovations for clients, family, and myself. You can check out my own renovation project in the Spring 2012 edition of Saskatoon Fine Homes—read about my <a href=" https://issuu.com/dwaynemelcher/docs/fhs_spring_2012">“conda-low”.</a> (Starts on p.  97)</p>
  </div>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" style="padding-top: 0">
  <div fxFlex="50" class="mat-elevation-z2 info-card">
  <h3>Educated</h3>
  <p>PhD, MEd, BEd</p>

  <p>My focus is on how people change/learn and how to maximize the learning environment AND remove blocks and impediments. I have additional training in Appreciative Inquiry, Quality Improvement, Conscious Leadership, 4MAT, Tiny Habits, ADKAR, Action Research, The Drama Triangle, Crucial Conversations, Non-Violent Communication, and Radical Candor.</p>
  </div>

  <div fxFlex="50" class="mat-elevation-z2 info-card">
  <h3>Energizing and Energetic</h3>
  <p>I change the energy in rooms. My intention is to inspire and enthuse to help groups achieve their goals. I use strengths-based approaches to add a little extra boost of positive energy and optimism. One of my workshops is “Shine and RISE!” A positive attitude yields positive results. I bring that positive attitude to your organization along with NEUTRALITY. As some of my clients are fond of saying, “Sheryl has no horse in the race.” My only agenda is you achieving your goals.</p>
  </div>
  </div>
  <p>To schedule an initial chat/consultation to determine fit, you can reach me at <a href="mailto:sheryl@millsconsult.com">sheryl@millsconsult.com</a></p>
</div>

<div #movingForward class="section" fxLayout="column" fxLayoutAlign="center center">
  <h1>moving forward together</h1>
  <h3>Co-create, co-develop, and collaborate: Together we are stronger!</h3>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
  <div fxFlex="50"class="mat-elevation-z2 info-card">
  <h3>Find out more about me...</h3>
  <p>You might want to watch one of the videos in the <a (click)="scrollTo(resources)">Resources</a> section to see if my style is what you are looking for before we chat. If you would like to contact my past clients for references, send me an email.</p>
  </div>
  <div fxFlex="50"class="mat-elevation-z2 info-card">
  <h3>Our initial chat...</h3>
  <p>We’ll find out if what I offer meets your needs. We can discuss your needs and my fees so we are clear on the expectations and conditions on both sides. Through our conversation, the compelling “why” will become clear.</p>
  </div>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" style="padding-top: 0">
  <div fxFlex="50" class="mat-elevation-z2 info-card">
  <h3>Setting up...</h3>
  <p>We get a bead on what you want to be different—assess the current state (your point A :) and clarify your preferred future (point B :). Using the equation [D x V] + IS > Inertia/resistance = CHANGE and ADKAR, we’ll figure out what steps make the most sense in your situation.
  </p>
  </div>

  <div fxFlex="50" class="mat-elevation-z2 info-card">
  <h3>Into the work...</h3>
  <p>Once the goal is clear, the path emerges. We bounce ideas around until we come up with a process that works for you. I provide a practical, straightforward approach to developing the best process to achieve your goals.</p>
  </div>
  </div>
  <p>To schedule an initial chat/consultation to determine fit, you can reach me at <a href="mailto:sheryl@millsconsult.com">sheryl@millsconsult.com</a></p>
</div>
<div #now class="light section" fxLayout="column" fxLayoutAlign="center center">
  <h1>now</h1>
  <h3>For references or to schedule an initial chat/consultation to determine fit</h3>
  <a mat-raised-button color="primary" href="mailto:sheryl@millsconsult.com">Contact Me</a>
</div>


<div class="section">
<h3 #resources style="margin: 24px;">Resources</h3>
<div fxLayout="row" fxLayoutAlign="space-around center"
fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" fxLayoutGap="24px" style="padding: 24px;">
<div class="vid-container mat-elevation-z2">
<iframe class="video"
src="https://www.youtube.com/embed/96SkOkMynXQ"
frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
</div>
<div class="vid-container mat-elevation-z2">
<iframe class="video"
src="https://www.youtube.com/embed/bczZcC1kFCQ"
frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
</div>
<div class="vid-container mat-elevation-z2">
<iframe class="video"
src="https://www.youtube.com/embed/dSZRRgpri-M"
frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
</div>
</div>
</div>
</div>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('nav') navbar: ElementRef;
  fixedNav = false;
  fixedMenu = false;
  contentPadding = 0;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    if (this.navbar.nativeElement) {
      this.fixedNav = window.pageYOffset > this.navbar.nativeElement.offsetTop;
      this.contentPadding = this.fixedNav ? this.navbar.nativeElement.clientHeight : 0;
    }
  }

  scrollTo(el: any) {
    const y = el.offsetTop - window.pageYOffset - (window.innerWidth > 600 ? 48 : 72);
    window.scrollBy({behavior: 'smooth', top: y});
  }
}
